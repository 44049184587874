import { LoadingController, ModalController, Platform } from "@ionic/angular";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SignaturePad } from "angular2-signaturepad";
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AlertService } from '../../../services/alert.service';
@Component({
  selector: "app-firm-modal",
  templateUrl: "./firm-modal.component.html",
  styleUrls: ["./firm-modal.component.scss"],
})
export class FirmModalComponent implements OnInit {
  @ViewChild("signaturePad", { static: false }) signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    maxWidth: 1,
    minWidth: 1,
    canvasWidth: window.innerWidth + 180,
    canvasHeight: 120
  };
  @Input() title: any;
  @Input() subtitle: any;

  public signatureImage: string;
  checkbox: any = "inactivo";
  isLoading: boolean = false;
  web:any=false
  constructor(
    private modalcontroller: ModalController,
    public loadingController: LoadingController,
    private screenOrientation: ScreenOrientation,
    private platform:Platform,
   ) {
      
      if (this.platform.is('cordova')) 
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE)
      else
        this.web=true
    }

  ngOnInit() {
   
  }

  drawComplete() {
    this.signatureImage = this.signaturePad.toDataURL();
  }
  clear() {
    this.signatureImage = "";
    this.signaturePad.clear();
  }
  closeModal() {
    this.modalcontroller.dismiss();
  }
  ionViewWillLeave(){
    if (this.platform.is('cordova')) 
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
  }
  firm() {
    this.modalcontroller.dismiss({
      checkbox:this.checkbox,
      signed_image:this.signatureImage.split(',')[1]
    });
  }

  changeBackground(checked) {
    if (checked) 
      this.checkbox = "activo";
     else 
      this.checkbox = "inactivo";
    
  }
 

}
