import { PayslipFirmComponent } from './../payslip-firm/payslip-firm.component';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ViewEmployeeVoicePage } from 'src/app/modules/employee-voice/view-employee-voice/view-employee-voice.page';
import { ReceiveRecognizeComponent } from 'src/app/modules/recognize-team/modals/receive-recognize/receive-recognize.component';
import { RecognizeByPage } from 'src/app/modules/recognize-team/recognize-by/recognize-by.page';
import { PushService } from 'src/app/services/push.service';
import { RecognizeService } from 'src/app/services/recognize.service';
import { SurveyService } from 'src/app/services/survey.service';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { PayrollAdvanceService } from '../../../services/payroll-advance.service';
import { SuaLetterService } from '../../../services/sua-letter.service';
import { ToastController } from "@ionic/angular";

@Component({
  selector: 'app-notifications-home-modal',
  templateUrl: './notifications-home-modal.component.html',
  styleUrls: ['./notifications-home-modal.component.scss'],
})
export class NotificationsHomeModalComponent implements OnInit {

  notifications:any;
  data: any;

  interactive_notifications:any = [
    'VOZ DEL EMPLEADO LEIDO',
    'VOZ DEL EMPLEADO ATENDIDO',
    'RECORDATORIO ENCUESTA',
    'ENCUESTA RECIBIDA',
    'RECONOCIMIENTO RECIBIDO',
    'RECORDATORIO MENSAJE',
    'MENSAJE RECIBIDO',
    'CARPETA CREADA',
    'DOCUMENTO SUBIDO',
    'TU RECIBO DE NOMINA YA ESTA DISPONIBLE',
    'DOCUMENTO BIENESTAR EN LINEA SUBIDO',
    'POLIZA DE SEGURO DISPONIBLE',
    'CARTA SUA'
  ]

  constructor(
    public modalController: ModalController,
    public pushService: PushService,
    private surveyService: SurveyService,
    private router: Router,
    private recognizeService: RecognizeService,
    private previewFile: PreviewAnyFile,
    private platform: Platform,
    private payrollAdvanceService: PayrollAdvanceService,
    private suaLetterService:SuaLetterService,
    private toastCtrl: ToastController 
  ) { }

  ngOnInit() {

    this.getNotifications();

  }
  closeModal() {
    this.eraseAll().then(()=>{
      this.modalController.dismiss();
    }).catch(err=>{
      console.log('err clean noti',err)
      this.modalController.dismiss();
    })
  }
  goPolice(item){

    this.pushService.notificationsMarkAs(item.id).subscribe(
      (data:any)=>{
        console.log("response leido", data);

        this.modalController.dismiss();
        let navigationExtras: NavigationExtras = {
          state: {
            message: item.data,
            modal:true
          }
        };
        this.router.navigate(['/assist-secure/dashboard-select'],navigationExtras)
      }
    );
  }

  openPayroll(notification) {
    this.pushService.notificationsMarkAs(notification.id).subscribe(_ => {
    /*  this.payrollAdvanceService.getReceipt(notification.data.id).subscribe(receipt => {
        //this.payrollAdvanceService.itemSelected(receipt);
          this.ShowPayslip(receipt);
      });*/
      this.ShowPayslip(notification.data.id);
    });
  }

  async ShowPayslip(payslip:any) {
    this.payrollAdvanceService.getReceipt(payslip).subscribe(
     async (receipt) => {
  
      this.data = receipt;
    console.log(this.data);
    if(this.data.signed == false){

      let modal = await this.modalController.create({
        component: PayslipFirmComponent,
        componentProps:{data: this.data},
      });
      modal.present();
     const { data } = await modal.onWillDismiss();
      console.log("response",data);
      if (data) {
        this.payrollAdvanceService.getReceipt(this.data.id).subscribe((res:any)=>{
          console.log('get pewrool update',res)
          this.payrollAdvanceService.presentAlertChooseType(res)
        },(err)=>console.log('err get payslip',err))
      }

    }else{
      this.payrollAdvanceService.presentAlertChooseType(this.data)
    }
    });
  }

  getNotifications() {
    this.pushService.getNotifications().subscribe((data: any) => {
      this.pushService.notifications = data.notifications;
    },error=>{
      this.closeModal();
    });
  }

  async viewEmployeeVoice(message){
    this.modalController.dismiss();
    
    this.pushService.notificationsMarkAs(message).subscribe(
      async (data:any)=>{
        console.log("response leido", data);

        let modal= await this.modalController.create({
          component:ViewEmployeeVoicePage,
          componentProps: {
            message: message.data
          }
        }); 
        modal.present();
      }
    );
  }

  goFolder(item){
    this.pushService.notificationsMarkAs(item.id).subscribe((data:any)=>{
      if(typeof item['data']['folder'] != 'undefined'){
        this.closeModal();
        let navigationExtras: NavigationExtras = {
          state: {
            folder: item['data']['folder']
          }
        };
        this.router.navigate(['/corp-docs/corp-subdocs'], navigationExtras);
      }
      if(typeof item['data']['subfolder'] != 'undefined'){
        this.closeModal();
        let navigationExtras: NavigationExtras = {
          state: {
            subfolder: item['data']['subfolder'],
            previous_folder: item['data']['subfolder']['folder']
          }
        };
        this.router.navigate(['/corp-docs/corp-subdocs-files'],navigationExtras);
      }
    });
  }

  goFile(item,index){
    this.pushService.notificationsMarkAs(item.id).subscribe((data:any)=>{
      this.openFile(item.file_url);
      this.pushService.notifications.splice(index,1);
    });
  }

  openFile(path) {
    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
      window.open(path, '_system');
    } else {
      this.previewFile.preview(path)
        .then((res: any) => console.log(res))
        .catch((error: any) => console.error(error));
    }
  }

  async showRecognize(recognize,shipping){
    const modal= await this.modalController.create({
        component:ReceiveRecognizeComponent,
        componentProps: {
          'recognize': recognize,
          'shipping':shipping
        }
    });
    modal.onDidDismiss().then(data=>{
      console.log(data);
      if(data['data']['read']){
        this.showRecognizeDetail(shipping);  
      }
    })

    return await modal.present();
  }

    // Presenta el toast con el error
    async presentErrorToast(msg) {
      const toast = await this.toastCtrl.create({
        message: msg,
        duration: 3000,
        position: "top",
        color: "danger",
        cssClass: "toast",
      });
      toast.present();
    }

  async showRecognizeDetail(shipping){
    let modal= await this.modalController.create({
      component:RecognizeByPage,
      componentProps: {
        'shipping': shipping
      }
    });
    modal.present();
  }

  goMessage(item){

    this.pushService.notificationsMarkAs(item.id).subscribe(
      (data:any)=>{
        console.log("response leido", data);

        this.modalController.dismiss();
        let navigationExtras: NavigationExtras = {
          state: {
            message: item.data,
            modal:true
          }
        };
        this.router.navigate(['/message/mensaje'],navigationExtras)
      }
    );
  }

  goSurvey(item){
    let body = { 'survey_shippings_id' : item.data.shipping_id };
    this.surveyService.hasSurveys(body).subscribe((response_surveys:any)=>{
      if(response_surveys.status == 200){
        let count = response_surveys.data.count;
        if(count > 0){
          this.redirectToSurvey(item);
        }else{
          this.presentErrorToast("La encuesta no se encuentra disponible o ya fue respondida");
        }
      }
    });
  }

  redirectToSurvey(item){
    this.pushService.notificationsMarkAs(item.id).subscribe((data:any)=>{
      this.modalController.dismiss();
      let navigationExtras: NavigationExtras = {
        state: {
          survey: item.data,
        }
      };
      this.router.navigate(['survey/bienvenida'],navigationExtras);
      this.getNotifications();
    });
  }


  goRecognize(item){
    
    this.pushService.notificationsMarkAs(item.id).subscribe(
      async (data:any)=>{
        console.log("response leido", data);
        console.log("response recono", item.data.acknowledgment_id);

        await this.recognizeService.getRecognizeDetailData(item.data.acknowledgment_id).subscribe(
          (data:any)=>{
            console.log(data);
            if(data.status == 200){
              let acknowledgment = data.acknowledgment;
              let shippings = data.shippings;
    
              if(acknowledgment.count > acknowledgment.necessary_mentions){
                acknowledgment.count = acknowledgment.necessary_mentions;
              }
              
              let shipping = shippings.find(element => element.id == item.data.id);
    
              if(shipping){
                this.showRecognize(acknowledgment,shipping);
              }
    
            }else{
    
            }
          }
        );

      }
    );



  }

  async eraseAll(){

    let notifications_id:any =[];
    console.log('notifications',this.pushService.notifications)
    await this.pushService.notifications.forEach(element => {
      notifications_id.push(element.id);
    });

    let data = {
      notifications_id:notifications_id,
      status:'LEIDA'
    }

    this.pushService.notificationsAllMarkAs(data).subscribe(
       (data:any)=>{
        console.log("response leido", data);

        if(data.status == 200){
          this.getNotifications();
        }
      }
    );

    
    
  }
  async eraseOnlyNotiText(){

    let notifications_id:any =[];
    await this.pushService.notifications.forEach(element => {
      if(!this.interactive_notifications.includes(element.type))
      notifications_id.push(element.id);
    });
    let data = {
      notifications_id:notifications_id,
      status:'LEIDA'
    }
    if(notifications_id.length > 0)
    this.pushService.notificationsAllMarkAs(data).subscribe(
      async (data:any)=>{
        console.log("response leido", data);
      }
    );
  }
  goSuaLetter(item){
    this.pushService.notificationsMarkAs(item.id).subscribe(
      (data:any)=>{
        console.log("response leido", data);
        this.modalController.dismiss();
        this.suaLetterService.ShowSuaLetter(item.data)
        this.getNotifications();
      }
    );
  }

}
