import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { SurveyService } from '../services/survey.service';

@Injectable({
  providedIn: 'root'
})
export class ExitPollGuard implements CanActivate {

  constructor(
    private router: Router,
    private surveyService: SurveyService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve) => {
        await this.surveyService.getExitPoll().subscribe(response => {
            let hasSurvey = response['status'] == 200 && typeof response['survey'] != 'undefined' ? true : false;
            if(hasSurvey){
                let survey = response['survey'];
                let navigationExtras: NavigationExtras = {
                state: {
                    survey: survey,
                }
                };
                this.router.navigate(['survey/survey-available'],navigationExtras);
            }
            resolve(!hasSurvey);
        },(error) => {
          resolve(true);
       });
    });
  }
}
