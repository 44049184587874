import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RecognizeService } from 'src/app/services/recognize.service';

@Component({
  selector: 'app-receive-recognize',
  templateUrl: './receive-recognize.component.html',
  styleUrls: ['./receive-recognize.component.scss'],
})
export class ReceiveRecognizeComponent implements OnInit {

  @Input() recognize;
  @Input () shipping;
  read: boolean = false;

  constructor(private modalController:ModalController, private recognizesService:RecognizeService) { }

  ngOnInit() {}

  close(){
    this.modalController.dismiss({'read': this.read});
  }

  async postMark(){
    let data :any = {
      acknowledgment_shipping_id: this.shipping.id,
      status: "Read"
    }
    this.recognizesService.postRecognizeSeen(data).subscribe(response => {
      if(response['status'] == 200){
        this.read = true;
        this.close();
      }
    });
  }

}
