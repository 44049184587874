import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

// Expire time in seconds
const TTL = 60 * 60;
// Key to identify only cached API data
const CACHE_KEY = '_cached_';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(private storage: Storage) { }

  // Store request data
  cacheRequest(url, data, duration, type = 'weeks'): Promise<any> {
    const validUntil = this.getCacheDuration(duration,type);
    url = `${CACHE_KEY}${url}`;
    return this.storage.set(url, {validUntil, data});
  }

  getCacheDuration (value, type, date = new Date()) {
    if(type == 'weeks'){
      date.setDate(date.getDate() + value * 7)
    }else if(type == 'hours'){
      date.setTime(date.getTime() + value * TTL * 1000);
    }else if(type == 'custom'){
      date.setTime(value);
    }
    return date.getTime();
  }

  // Try to load cached data
  async getCachedRequest(url): Promise<any> {
    const currentTime = new Date().getTime();
    url = `${CACHE_KEY}${url}`;

    const storedValue = await this.storage.get(url);

    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime) {
      await this.storage.remove(url);
      return null;
    } else {
      return storedValue.data;
    }
  }

  // To remove one cached URL
  async invalidateCacheEntry(url) {
    url = `${CACHE_KEY}${url}`;
    await this.storage.remove(url);
  }
}
