import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class RecognizeService {

  connected: boolean = navigator.onLine;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  }

  userSelected:any=[];
  users:any =[];

  acknowledgments:any;
  
  constructor(
    private http: HttpClient,
    private storage: Storage,
    public loadingCtrl: LoadingController,
    private cacheService: CacheService
  ) {
    
  }

  //Obtener data
  getRecognizesData() {
    return this.http.get(environment.API_URL + '/acknowledgments/get_data', this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data
  getRecognizes(forceRefresh = false) {
    let url = `${environment.API_URL}/acknowledgments`;

    // Handle offline case
    if (!this.connected) {
      return from(this.cacheService.getCachedRequest(url));
    }

    // Handle connected case
    if (forceRefresh) {
      // Make a new API call
      return this.getCallAndCache(url);
    } else {
      // Check if we have cached data
      const storedValue = from(this.cacheService.getCachedRequest(url));
      return storedValue.pipe(
        switchMap(result => {
          if (!result) {
            // Perform a new request since we have no data
            return this.getCallAndCache(url);
          } else {
            // Return cached data
            return of(result);
          }
        })
      );
    }
  }

  getCallAndCache(url): Observable<any> {
    return this.http.get(url).pipe(
      tap(res => {
        // Store our new data
        this.cacheService.cacheRequest(url, res, 1);
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data
  getReceivers(page,search) {
    let parameters = '';
    if(this.isEmpty(page) && !this.isEmpty(search)){
      parameters = '?search='+search;
    }else if(!this.isEmpty(page) && this.isEmpty(search)){
      parameters = '?page='+page;
    }else if(!this.isEmpty(page) && !this.isEmpty(search)){
      parameters = '?search='+search+'&page='+page;
    }
    let endpoint = '/acknowledgments/get_receivers' + parameters;
    return this.http.get(environment.API_URL + endpoint, this.httpOptions)
    .pipe(
      tap(receivers => {
        return receivers;
      }),
      catchError(this.handleError)
    )
  }

  isEmpty(str) {
    return (!str || str.length === 0 );
  }

  //Post data
  poastRecognizesData(data:any) {
    return this.http.post(environment.API_URL + '/acknowledgments/send', data, this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data recibidos
  getMyRecognizesData() {
    return this.http.get(environment.API_URL + '/acknowledgments/get_shippings', this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data individual
  getRecognizeDetailDataPaginated(id,page = null) {
    let parameters = '';
    if(!this.isEmpty(page)){
      parameters = parameters + '?page='+page;
    }
    let endpoint = '/acknowledgments/list_acknowledgment_paginated/' + id + parameters;
    return this.http.get(environment.API_URL + endpoint, this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data individual
  getRecognizeDetailData(id) {
    return this.http.get(environment.API_URL + '/acknowledgments/list_acknowledgment/' + id, this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data de envio de reconocimiento
  getRecognizeShipping(id) {
    return this.http.get(environment.API_URL + '/acknowledgments/get_shipping/' + id, this.httpOptions)
    .pipe(
      tap(recognize => {
        return recognize;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener ultimo data recibido  
  getRecognizeLastData() {
    return this.http.get(environment.API_URL + '/acknowledgments/get_new_acknowledgment', this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener ultimo data recibido  
  postRecognizeSeen(shipping_id) {
    return this.http.post(environment.API_URL + '/acknowledgments/mark_as',shipping_id, this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.'
    );
  };


  //Filtrar busqueda
  filterItems(searchTerm){
    console.log(searchTerm);
    if(searchTerm == ''){
      //console.log('vacio');
      return this.users;
    }
    //console.log('filro');
    return this.users.filter((user) => {
      return user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  //Marcar reaccion
  markReaction(recognize_id, reaction){
    let data = { 'recognize_id' : recognize_id, 'reaction' : reaction };
    return this.http
      .post(environment.API_URL+'/acknowledgments/mark_reaction', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }
  getRecognizesDataRecord() {
    return this.http.get(environment.API_URL + '/acknowledgments/list_acknowledgments_made', this.httpOptions)
    .pipe(
      tap(recognizes => {
        return recognizes;
      }),
      catchError(this.handleError)
    )
  }
}
