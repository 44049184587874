import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { VideoPlayerComponent } from '../components/modals/video-player/video-player.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { BehaviorSubject } from 'rxjs';
import { SuaLetterService } from './sua-letter.service';


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  loading: boolean = false;

  hasErr:any=false
  errorMsg = new BehaviorSubject("");
  errorExist = new BehaviorSubject(false);


  constructor( private androidPermissions: AndroidPermissions,private nativeHTTP: HTTP,private loadingCtrl:LoadingController,private toastController: ToastController,private alertController:AlertController,private modalController:ModalController, private socialSharing : SocialSharing, private file: File,  public platform: Platform,
    private suaLetterService:SuaLetterService
   ) { }

  async presentToast(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      color: 'dark'
    });
    toast.present();
  }
  async presentToastError(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
      position: 'top',
      color: 'danger'
    });
    toast.present();
  }
  async presentAlert(header:string,message:string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });

    await alert.present();
  }
  async presentLoading(m?:string) {
    this.loading = true;
    return await this.loadingCtrl.create({
      message: m,
    }).then(a => {
      a.present().then(() => {
        if (!this.loading) {
          a.dismiss().then(() => console.log());
        }
    });
    });
  }
  async dismissLoading() {
    this.loading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }
  isFileVideo(path: string): boolean {
    const ext = path.endsWith('.mp4') || path.endsWith('.MOV') || path.endsWith('.webm');
    return ext 
  }
  isFilePdf(path: string): boolean {
    const ext = path.substr(path.length - 4, path.length);
    return ext === '.pdf' ? true : false;
  }
  isFileExcel(path: string): boolean {
    const ext = path.endsWith('.xlsx') || path.endsWith('.xls');
    return ext
  }
  isFileWord(path: string): boolean {
    const ext = path.endsWith('.docx') || path.endsWith('.doc');
    return ext
  }
  isFilePowerPoint(path: string): boolean {
    const ext = path.endsWith('.ppt') || path.endsWith('.pptx');
    return ext
  }
  isFileImage(path: string): boolean {
    let lowerPath=path.toLocaleLowerCase()
    const ext = lowerPath.endsWith('.jpg') || lowerPath.endsWith('.jpeg') || lowerPath.endsWith('.png');
    return ext
  }
  async openVideoPlayer(path_video:string,title_video?:string){
    let modal= await this.modalController.create({
      component:VideoPlayerComponent,
      componentProps:{
        path_video,
        title_video
      }
    });
    modal.present();
  }

       async downloadFile(url,name){
        if(!this.platform.is('cordova')){
           return
        }
        this.presentLoading();
        let path = await this.getDownloadPath()
        let final_path = path + name; 
        this.nativeHTTP.downloadFile(encodeURI(url), {}, {}, encodeURI(final_path)).then(response => {
          this.dismissLoading();
          console.log('success block...', JSON.stringify(response));
          this.presentToast('Archivo descargado')
       }).catch(err => {
        this.dismissLoading();
           console.log('error block ... ', err.status);
           console.log('error block ... ', err.error);
       })
       }
       async downloadShare(url,name){
        if(!this.platform.is('cordova')){
          return
       }
        this.presentLoading();
        let path = await this.getDownloadPath()
        let final_path = path + name; 
        this.nativeHTTP.downloadFile(encodeURI(url), {}, {}, encodeURI(final_path)).then(response => {
          this.dismissLoading();
          console.log('success block...', JSON.stringify(response));
          this.socialSharing.share(null, null,response.nativeURL, null).then((data) => {
            console.log('Shared via SharePicker'); 
           }).catch((err) => {
            console.log('Was not shared via SharePicker',JSON.stringify(err))
          });
       }).catch(err => {
        this.dismissLoading();
           console.log('error block ... ', err.status);
           console.log('error block ... ', err.error);
       })
       }
      async getDownloadPath() {
        if (this.platform.is('ios')) {
          return this.file.documentsDirectory;
        }
        await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
          result => {
            if (!result.hasPermission) {
              return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
            }
          }
        );
        return this.file.externalRootDirectory + "/Download/";
    }
    setErrMessage(msg: string) {
      this.errorMsg.next(msg);
    }
    setExistError(msg: boolean) {
      this.errorExist.next(msg);
    }
    getDifferenceInDays(date){
      let date1=new Date()
      let date2 = new Date(date)
      let difference_In_Time =date1.getTime() - date2.getTime();
      console.log('days difference',Math.abs(Math.round(difference_In_Time / (1000 * 3600 * 24))-1))
      return Math.abs(Math.round(difference_In_Time / (1000 * 3600 * 24)) - 1);
    }
    openFile(data){
      if(this.platform.is('cordova'))
        this.suaLetterService.createpdf(data.pdf_string,'Carta SUA',data.id)
      else{
        this.suaLetterService.readSuaLetter(data.id);
        var contentType = "application/pdf";
        var dataBlob = this.suaLetterService.convertBase64ToBlob(data.pdf_string, contentType);
        window.open(URL.createObjectURL(dataBlob),'_system');
      }
    }
    async presentAlertCustom(header,message) {

      const alert = await this.alertController.create({
        cssClass: 'custom-alert',
        backdropDismiss:false,
        header,
        keyboardClose:false,
        subHeader:message,
        message:`<ion-row class="spinner"><ion-spinner></ion-spinner></ion-row>`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Aceptar',
            handler: async () => {
           
            },
          },
        ],
      });
      await alert.present();
      
  
    }
    async closeAlertCustom(){
      return await this.alertController.dismiss().then(() => console.log('dismissed'));
    }
    
}