import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { retry, catchError, tap, finalize } from 'rxjs/operators';
import { LoadingController } from "@ionic/angular";

type SurveyResponse = {
  status: number;
  successMessage: string;
  data: any;
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  isLoading: boolean = false;
  

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  }

  constructor(
    private http: HttpClient,
    public loadingCtrl: LoadingController
  ) { 
  
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  //Obtener encuestas
  getPaginatedSurveys(page = null) {
    let parameters = '';
    if(!this.isEmpty(page)){
      parameters = parameters + '?page='+page;
    }
    let endpoint = '/surveys' + parameters;
    return this.http.get(environment.API_URL + endpoint, this.httpOptions)
    .pipe(
      tap(surveys => {
        return surveys;
      }),
      catchError(this.handleError)
    )
  }

  isEmpty(str) {
    return (!str || str.length === 0 );
  }

  //Consultar si hay en
  hasSurveys(data){
    return this.http
      .post(environment.API_URL+'/surveys/has_surveys', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Obtener encuestas
  getSurveys() {
    return this.http.get(environment.API_URL + '/surveys/get_list', this.httpOptions)
    .pipe(
      tap(surveys => {
        return surveys;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener encuesta urgente
  getImportantSurvey() {
    return this.http.get(environment.API_URL + '/surveys/get_important', this.httpOptions)
    .pipe(
      tap(survey => {
        return survey;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener encuesta urgente
  getExitPoll() {
    return this.http.get(environment.API_URL + '/surveys/has_exit_poll', this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener encuesta por id de envio
  getSurvey(survey_shipping_id) {
    return this.http.get(environment.API_URL + '/surveys/get_survey/'+survey_shipping_id, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener pregunta nps por id de encuesta
  getNpsQuestion(survey_shipping_id) {
    return this.http.get(environment.API_URL + '/surveys/get_nps_question/'+survey_shipping_id, this.httpOptions)
    .pipe(
      tap((nps_question) => {
        return nps_question;
      }),
      catchError(this.handleError)
    )
  }

  //Obtener data
  getData(url) {
    return this.http.get(url, this.httpOptions)
    .pipe(
      tap((questions) => {
        return questions;
      }),
      catchError(this.handleError)
    )
  }

  //Envio de seccion
  fillSection(data){
    // Presentamos el Loading al inicio de la llamada
    //this.presentLoading();
    return this.http
      .post(environment.API_URL+'/surveys/fill_section', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        //finalize(() => this.dismissLoading()),
        catchError(this.handleError)
      )
  }
  fillAllSections(data){
    // Presentamos el Loading al inicio de la llamada
    //this.presentLoading();
    return this.http
      .post(environment.API_URL+'/surveys/fill_all_sections', JSON.stringify({'responses_sections':data}), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        //finalize(() => this.dismissLoading()),
        catchError(this.handleError)
      )
  }

  //Envio de pregunta opcional
  fillOptionalQuestion(data){
    // Presentamos el Loading al inicio de la llamada
    //this.presentLoading();
    return this.http
      .post(environment.API_URL+'/surveys/fill_optional_question', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        //finalize(() => this.dismissLoading()),
        catchError(this.handleError)
      )
  }
  fillAllOptionalQuestions(data){
    // Presentamos el Loading al inicio de la llamada
    //this.presentLoading();
    return this.http
      .post(environment.API_URL+'/surveys/fill_all_optional_questions', JSON.stringify({responses_optional_questions:data}), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        //finalize(() => this.dismissLoading()),
        catchError(this.handleError)
      )
  }
  //Envio de total de encuesta
  fillSurveyTotal(data){
    // Presentamos el Loading al inicio de la llamada
    return this.http
      .post(environment.API_URL+'/surveys/fill_total', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  // Creación del loading
  async presentLoading() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: 'Estamos guardando sus datos.'
    }).then((a) => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  // Cierre del loading
  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss();
  }
}
